import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form } from 'react-final-form';
import styled from 'styled-components';
import { palette } from 'styled-tools';
import { compose, pure } from 'recompose';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import createDecorator from 'final-form-calculate';
import createFocusDecorator from 'final-form-focus';
import * as R from 'ramda';

import { Button, Icon } from 'components/shared';
import { FormFlex, FormBox, FormGroupAdapter, Field } from 'components/shared/form';
import {
  getAvailableConstructionWorksRefs,
  getActualTypesCollectionRefs,
  getApplicationByToken,
  getConstructionBudgetInitialValues,
  requestInProcess,
  getApplicationPrimaryBorrower,
  getApplicationPropertyById,
} from 'selectors';
import * as actions from 'actions';

import { getIn } from 'utils';

const propTypes = {
  initialValues: PropTypes.shape({}).isRequired,
  primaryBorrower: PropTypes.shape({}),
  property: PropTypes.shape({}).isRequired,
  availableConstructionWorks: PropTypes.arrayOf(PropTypes.shape({})),
  availablePropertyTypes: PropTypes.arrayOf(PropTypes.shape({})),
  fetchConstructionTemplate: PropTypes.func.isRequired,
  editConstructionBudgetRequest: PropTypes.func.isRequired,
  importConstructionBudgetRequest: PropTypes.func.isRequired,
  templateLoading: PropTypes.bool,
  importing: PropTypes.bool,
  availableToEditConstructionBudget: PropTypes.bool
};

const defaultProps = {
  primaryBorrower: {},
  availableConstructionWorks: [],
  availablePropertyTypes: [],
  templateLoading: false,
  importing: false,
};

const focusOnError = createFocusDecorator();

const calculator = createDecorator({
  field: /constructionWorks\[\d+\].amount/,
  updates: {
    totalConstructionBudget: (ignoredValue, allValues) =>
      (allValues.constructionWorks || []).reduce((sum, value) => sum + Number(parseFloat(value.amount || 0) || 0), 0),
  },
});

class ConstructionBudgetForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUploadFormOpen: false,
    };
  }

  handleToggleUploadForm() {
    this.setState((prevState) => ({
      isUploadFormOpen: !prevState.isUploadFormOpen,
    }));
  }

  render() {
    const {
      property,
      availableConstructionWorks,
      availablePropertyTypes,
      initialValues,
      fetchConstructionTemplate,
      editConstructionBudgetRequest,
      importConstructionBudgetRequest,
      templateLoading,
      importing,
      primaryBorrower,
      availableToEditConstructionBudget,
      match: { params },
    } = this.props;

    const { isUploadFormOpen } = this.state;
    const { token, propertyId } = params;
    const propertyType = R.find(
      R.pipe(
        getIn('value'),
        R.equals(initialValues.type),
      ),
      availablePropertyTypes,
    );

    const onSubmit = async (values) => {
      const result = await editConstructionBudgetRequest(values, token, propertyId);
      return result.constructionBudget;
    };

    return (
      <React.Fragment>
        {!availableToEditConstructionBudget && (
          <InfoBox>
            <StyledIcon icon="info" width={1}/>
            <InfoBoxText>
              Updates to the Construction Budget are unavailable at this stage in the loan process. Please reach out to the Draws Team if you require assistance on your construction budget.
            </InfoBoxText>
          </InfoBox>
        )}
        <FormFlex alignItems="center" justifyContent="flex-start">
          <FormBox pl={3} pr={3}>
            <StyledConstructionBudgetFormHeader>
              <span>&nbsp;</span>
              <Button
                disabled={templateLoading || importing || !availableToEditConstructionBudget}
                sm
                onClick={() => fetchConstructionTemplate(token, propertyId)}
              >
                {templateLoading ? 'Downloading...' : 'Download Template'}
              </Button>
              <span>&nbsp;</span>
              <StyledConstructionBudgetFormHeaderImport>
                <Button sm disabled={importing || templateLoading || !availableToEditConstructionBudget} onClick={() => this.handleToggleUploadForm()}>
                  {importing ? 'Importing...' : 'Import'}
                </Button>
                <span>&nbsp;</span>
                {isUploadFormOpen && (
                  <div>
                    Choose file{' '}
                    <input
                      type="file"
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      onChange={(e) => {
                        importConstructionBudgetRequest(e.target.files[0], token, propertyId);
                        this.handleToggleUploadForm();
                      }}
                    />
                  </div>
                )}
              </StyledConstructionBudgetFormHeaderImport>
            </StyledConstructionBudgetFormHeader>
          </FormBox>
        </FormFlex>
        <br />
        <FormFlex alignItems="center" justifyContent="flex-start">
          <FormBox pl={3} pr={3}>
            <p className="repair_budget_form__address">
              <b>Property Address:</b> {(property && property.address) || 'N/A'}
            </p>
            <p className="repair_budget_form__address">
              <b>Borrower Name:</b>{' '}
              {(primaryBorrower && `${primaryBorrower.firstName || ''} ${primaryBorrower.lastName || ''}`) || 'N/A'}
            </p>
          </FormBox>
        </FormFlex>
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          decorators={[focusOnError, calculator]}
          mutators={{ ...arrayMutators }}
          validate={(values) => {
            const errors = {};
            let hasErrors = false;

            errors.constructionWorks = [];

            if (values && values.constructionWorks) {
              R.forEach((work) => {
                let constructionWorksErrors = {};
                if (work && !work._destroy && !work.categoryId) {
                  constructionWorksErrors = {
                    ...constructionWorksErrors,
                    categoryId: 'must be filled',
                  };
                  hasErrors = true;
                }
                errors.constructionWorks.push(constructionWorksErrors);
              }, values.constructionWorks);
            }

            return hasErrors ? errors : {};
          }}
          render={({
            handleSubmit,
            submitting,
            values,
            form: {
              mutators: { push },
            },
          }) => (
            <form onSubmit={handleSubmit}>
              <br />
              <FormFlex alignItems="center" justifyContent="flex-start">
                <FormBox pl={3} pr={3}>
                  <StyledConstructionWorksTable>
                    <thead>
                      <tr>
                        <th />
                        <th>Current Property</th>
                        <th>Proposed</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <b>Property Type</b>
                        </td>
                        <td>{initialValues.type && propertyType && propertyType.label} </td>
                        <td>
                          <Field
                            name="proposedType"
                            type="select"
                            options={availablePropertyTypes}
                            component={FormGroupAdapter}
                            height={26}
                            isDisabled={!availableToEditConstructionBudget}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <nobr>
                            <b>Square Footage</b>
                          </nobr>
                        </td>
                        <td>{initialValues.size || <i>Not Provided</i>}</td>
                        <td>
                          <Field
                            name="proposedSize"
                            typeOfMask="decimalNumber"
                            component={FormGroupAdapter}
                            placeholder="Square Footage"
                            rate={32}
                            disabled={!availableToEditConstructionBudget}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <nobr>
                            <b># of Bedrooms</b>
                          </nobr>
                        </td>
                        <td>{initialValues.bedroomsCount || <i>Not Provided</i>}</td>
                        <td>
                          <Field
                            name="proposedBedroomsCount"
                            typeOfMask="number"
                            component={FormGroupAdapter}
                            placeholder="# of Bedrooms"
                            rate={32}
                            disabled={!availableToEditConstructionBudget}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <nobr>
                            <b># of Baths</b>
                          </nobr>
                        </td>
                        <td>{initialValues.bathroomsCount || <i>Not Provided</i>}</td>
                        <td>
                          <Field
                            name="proposedBathroomsCount"
                            typeOfMask="decimalNumber"
                            component={FormGroupAdapter}
                            placeholder="# of Baths"
                            rate={32}
                            disabled={!availableToEditConstructionBudget}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </StyledConstructionWorksTable>
                </FormBox>
              </FormFlex>
              <br />
              <FormFlex alignItems="center" justifyContent="flex-start">
                <FormBox pl={3} pr={3}>
                  <div>
                    <b>Total Budget:</b>{' '}
                    {values && values.totalConstructionBudget
                      ? parseFloat(values.totalConstructionBudget).toLocaleString('en-US', {
                          style: 'currency',
                          currency: 'USD',
                        })
                      : 'N/A'}
                  </div>
                </FormBox>
              </FormFlex>

              <br />

              <FormFlex alignItems="center" justifyContent="flex-start">
                <FormBox pl={3} pr={3} width={1}>
                  <Field
                    name="constructionBudgetDescription"
                    type="textarea"
                    placeholder="Description"
                    label={
                      <span>
                        <span>Overall Description of the Work:</span>{' '}
                        <small>
                          Include description of the quality/type of the interior finishes, any conversions and any
                          additions or SF changes:
                        </small>
                      </span>
                    }
                    hint="(The level of detail will impact the accuracy of your After-Repair Valuation)"
                    component={FormGroupAdapter}
                    disabled={!availableToEditConstructionBudget}
                  />
                </FormBox>
              </FormFlex>

              <FormFlex alignItems="center" justifyContent="flex-start">
                <FormBox pl={3} pr={3} width={1}>
                  <StyledConstructionWorksTable>
                    <thead>
                      <tr>
                        <th>
                          <nobr>Construction Item</nobr>
                        </th>
                        <th>
                          <nobr>Description of Work</nobr>
                        </th>
                        <th className="text-right">Amount, $</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {values && (!values.constructionWorks || values.constructionWorks.length === 0) && (
                        <tr>
                          <td colSpan="3">No Construction Works Added</td>
                        </tr>
                      )}
                      <FieldArray name="constructionWorks">
                        {({ fields }) =>
                          fields.map((name, index) => (
                            <tr
                              key={name}
                              style={{
                                display: fields.value[index]._destroy && 'none',
                              }}
                            >
                              <td>
                                <Field
                                  name={`${name}.categoryId`}
                                  type="select"
                                  options={availableConstructionWorks}
                                  component={FormGroupAdapter}
                                  height={26}
                                  isDisabled={!availableToEditConstructionBudget}
                                />
                              </td>
                              <td width="60%">
                                <Field
                                  name={`${name}.description`}
                                  type="textarea"
                                  placeholder="Overall description of work"
                                  component={FormGroupAdapter}
                                  rate={32}
                                  disabled={!availableToEditConstructionBudget}
                                />
                              </td>
                              <td>
                                <Field
                                  name={`${name}.amount`}
                                  placeholder="Amount"
                                  typeOfMask="decimalCurrency"
                                  rate={34}
                                  component={FormGroupAdapter}
                                  disabled={!availableToEditConstructionBudget}
                                />
                              </td>
                              <td align="middle" className="repair_budget_form__works__remove">
                                <Button
                                  type="button"
                                  palette="danger"
                                  sm
                                  onClick={() => {
                                    const item = { ...fields.value[index] };
                                    if (item.id) {
                                      fields.remove(index);
                                      fields.insert(index, {
                                        ...item,
                                        amount: 0,
                                        _destroy: true,
                                      });
                                    } else {
                                      fields.remove(index);
                                    }
                                  }}
                                  disabled={!availableToEditConstructionBudget}
                                >
                                  &times;
                                </Button>
                                <Field name={`${name}._destroy`} component="input" type="hidden" />
                              </td>
                            </tr>
                          ))
                        }
                      </FieldArray>
                    </tbody>
                  </StyledConstructionWorksTable>
                </FormBox>
              </FormFlex>

              <Field name="totalConstructionBudget" component="input" type="hidden" />

              <StyledConstructionBudgetFormActions alignItems="center" justifyContent="flex-start">
                <FormBox pl={3} pr={3}>
                  <Button type="button" onClick={() => push('constructionWorks', { amount: 0 })} disabled={!availableToEditConstructionBudget}>
                    Add Construction Work
                  </Button>
                </FormBox>
                <FormBox pl={3} pr={3} alignSelf={['flex-end', 'flex-end', 'inherit']}>
                  <Button type="submit" disabled={submitting || !availableToEditConstructionBudget}>
                    {submitting ? 'Updating...' : 'Update'}
                  </Button>
                </FormBox>
                <FormBox pl={3} pr={3} alignSelf={['flex-end', 'flex-end', 'inherit']}>
                  <b>Total Budget:</b>{' '}
                  {values && values.totalConstructionBudget
                    ? parseFloat(values.totalConstructionBudget).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      })
                    : 'N/A'}
                </FormBox>
              </StyledConstructionBudgetFormActions>
            </form>
          )}
        />
      </React.Fragment>
    );
  }
}

const InfoBox = styled.p`
  padding: 10px 15px;
  margin: 0 15px 15px;
  display: flex;
  max-width: fit-content;
  border-radius: 8px;
  background-color: ${palette('bleakYellow')};
`;

const StyledIcon = styled(Icon)`
  margin: 3px 6px 0 0;
  min-width: 16px;
`;

const InfoBoxText = styled.span`
  display: block;
`;

const StyledConstructionWorksTable = styled.table`
  width: 100%;
  & th {
    padding: 5px 10px;
    vertical-align: top;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
  & td {
    padding: 5px 10px;
    vertical-align: top;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
`;

const StyledConstructionBudgetFormHeader = styled.div`
  display: flex;
`;
const StyledConstructionBudgetFormHeaderImport = styled.div`
  display: flex;
  align-items: center;
`;
const StyledConstructionBudgetFormActions = styled(FormFlex)``;

ConstructionBudgetForm.propTypes = propTypes;
ConstructionBudgetForm.defaultProps = defaultProps;

const mapStateToProps = (state, props) => {
  const { token } = props.match.params;
  const application = getApplicationByToken(state, token);
  return {
    availablePropertyTypes: getActualTypesCollectionRefs(state),
    availableConstructionWorks: getAvailableConstructionWorksRefs(state),
    initialValues: getConstructionBudgetInitialValues(state),
    templateLoading: requestInProcess(state, 'CONSTRUCTION_BUDGET_TEMPLATE'),
    importing: requestInProcess(state, 'CONSTRUCTION_BUDGET_IMPORT'),
    primaryBorrower: getApplicationPrimaryBorrower(state),
    property: getApplicationPropertyById(state, props),
    application,
  };
};
function mapDispatchToProps(dispatch) {
  return {
    fetchConstructionTemplate: bindActionCreators(actions.fetchConstructionTemplate, dispatch),
    editConstructionBudgetRequest: bindActionCreators(actions.editConstructionBudgetRequest, dispatch),
    importConstructionBudgetRequest: bindActionCreators(actions.importConstructionBudgetRequest, dispatch),
  };
}

const enhance = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  pure,
);

export default enhance(ConstructionBudgetForm);
