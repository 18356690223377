import objectToFormData from 'object-to-formdata';
import { lensPath, view, equals } from 'ramda';

import * as actionTypes from '../../constants/actionTypes';
import * as requestTypes from '../../constants/requestTypes';
import setRequestInProcess from '../../actions/request';

import api from '../../utils/api';
import { downloadResponseFile, errorNotification, successNotification, camelizeKeys } from '../../utils';
import { ERROR_403_CONSTRUCTION_BUDGET } from '../../constants/errors';

export const fetchConstructionBudget = (appToken, propertyId) => (dispatch) => {
  const requestType = requestTypes.CONSTRUCTION_BUDGET;
  const url = `/applications/${appToken}/construction_budget/${propertyId}`;

  dispatch(setRequestInProcess(true, requestType));
  return api
    .get(url)
    .then(({ data }) => {
      dispatch(setRequestInProcess(false, requestType));
      dispatch(fetchConstructionBudgetSuccess(data.constructionBudget));
    })
    .catch((response) => {
      const title = view(lensPath(['errors', 'title']), response);
      dispatch(setRequestInProcess(false, requestType));
      errorNotification(title || 'Internal server error!');
    });
};

export const fetchConstructionTemplate = (appToken, propertyId) => (dispatch) => {
  const requestType = requestTypes.CONSTRUCTION_BUDGET_TEMPLATE;
  const url = `/applications/${appToken}/construction_budget/${propertyId}/template`;

  dispatch(setRequestInProcess(true, requestType));
  return api
    .get(url)
    .then((response) => {
      dispatch(setRequestInProcess(false, requestType));
      dispatch(fetchConstructionBudgetTemplateSuccess(response.data));
      downloadResponseFile('template.xlsx', response);
    })
    .catch((response) => {
      dispatch(setRequestInProcess(false, requestType));
      const status = view(lensPath(['errors', 'status']), response);
      const title = view(lensPath(['errors', 'title']), response);
      if (equals(status, 403)) {
        errorNotification(ERROR_403_CONSTRUCTION_BUDGET);
        return;
      }
      errorNotification(title || "Internal server error!");
    });
};

export function fetchConstructionBudgetTemplateSuccess(payload) {
  return {
    type: actionTypes.CONSTRUCTION_BUDGET_TEMPLATE_FETCH_SUCCESS,
    payload,
  };
}

export function fetchConstructionBudgetSuccess(payload) {
  return {
    type: actionTypes.CONSTRUCTION_BUDGET_FETCH_SUCCESS,
    payload,
  };
}

export function clearConstructionBudget() {
  return {
    type: actionTypes.CONSTRUCTION_BUDGET_CLEAR,
  };
}

export const editConstructionBudgetRequest = (values, appToken, propertyId) => (dispatch) =>
  api
    .patch(`/applications/${appToken}/construction_budget/${propertyId}`, { construction_budget: { ...values } })
    .then(({ data }) => {
      dispatch(editConstructionBudget(data.constructionBudget));
      successNotification('Construction Budget was successfully updated');
    })
    .catch((response) => {
      const status = view(lensPath(['errors', 'status']), response);
      const source = view(lensPath(['errors', 'source']), response);
      const title = view(lensPath(['errors', 'title']), response);
      if (equals(status, 400)) {
        return camelizeKeys(source);
      }
      if (equals(status, 403)) {
        errorNotification(ERROR_403_CONSTRUCTION_BUDGET);
        return {};
      }
      errorNotification(title || "Internal server error!");
      return {};
    });

export function editConstructionBudget(data) {
  return {
    type: actionTypes.CONSTRUCTION_BUDGET_UPDATE,
    payload: {
      data,
    },
  };
}

export const importConstructionBudgetRequest = (asset, appToken, propertyId) => (dispatch) => {
  const requestType = requestTypes.CONSTRUCTION_BUDGET_IMPORT;
  const url = `/applications/${appToken}/construction_budget/${propertyId}/import`;
  const formData = objectToFormData({ import: { asset: undefined } });
  formData.append('import[asset]', asset);

  dispatch(setRequestInProcess(true, requestType));
  return api
    .patch(url, formData)
    .then(({ data }) => {
      dispatch(setRequestInProcess(false, requestType));
      dispatch(editConstructionBudget(data.constructionBudget));
      successNotification('Properties was successfuly imported');
    })
    .catch((response) => {
      dispatch(setRequestInProcess(false, requestType));
      const status = view(lensPath(['errors', 'status']), response);
      const message = view(lensPath(['errors', 'source', 'importer']), response);
      const title = view(lensPath(['errors', 'title']), response);
      if (equals(status, 400)) {
        dispatch(fetchConstructionBudget(appToken, propertyId));
        errorNotification(message);
        return;
      }
      if (equals(status, 403)) {
        errorNotification(ERROR_403_CONSTRUCTION_BUDGET);
        return;
      }
      errorNotification(title || "Internal server error!");
    });
};
