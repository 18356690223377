import React, { Fragment } from 'react';
import ContentLoader from 'react-content-loader';
import styled from 'styled-components';
import { palette } from 'styled-tools';

export default function Loader() {
  return (
    <Fragment>
      <LoaderTitle>
        Loading the quote...
      </LoaderTitle>
      <ContentLoader
        speed={2}
        width={1048}
        height={275}
        style={{margin: '15px 0 20'}}
      >
        {/* Row 1 */}
        <rect x="0" y="0" width="110" height="50" />
        <rect x="116" y="0" width="110" height="50" />
        <rect x="232" y="0" width="110" height="50" />
        <rect x="348" y="0" width="110" height="50" />
        <rect x="464" y="0" width="110" height="50" />
        <rect x="580" y="0" width="110" height="50" />
        <rect x="696" y="0" width="110" height="50" />
        <rect x="812" y="0" width="110" height="50" />
        <rect x="928" y="0" width="110" height="50" />

        {/* Row 2 */}
        <rect x="0" y="55" width="110" height="50" />
        <rect x="116" y="55" width="110" height="50" />
        <rect x="232" y="55" width="110" height="50" />
        <rect x="348" y="55" width="110" height="50" />
        <rect x="464" y="55" width="110" height="50" />
        <rect x="580" y="55" width="110" height="50" />
        <rect x="696" y="55" width="110" height="50" />
        <rect x="812" y="55" width="110" height="50" />
        <rect x="928" y="55" width="110" height="50" />

        {/* Row 3 */}
        <rect x="0" y="110" width="110" height="50" />
        <rect x="116" y="110" width="110" height="50" />
        <rect x="232" y="110" width="110" height="50" />
        <rect x="348" y="110" width="110" height="50" />
        <rect x="464" y="110" width="110" height="50" />
        <rect x="580" y="110" width="110" height="50" />
        <rect x="696" y="110" width="110" height="50" />
        <rect x="812" y="110" width="110" height="50" />
        <rect x="928" y="110" width="110" height="50" />

        {/* Row 4 */}
        <rect x="0" y="165" width="110" height="50" />
        <rect x="116" y="165" width="110" height="50" />
        <rect x="232" y="165" width="110" height="50" />
        <rect x="348" y="165" width="110" height="50" />
        <rect x="464" y="165" width="110" height="50" />
        <rect x="580" y="165" width="110" height="50" />
        <rect x="696" y="165" width="110" height="50" />
        <rect x="812" y="165" width="110" height="50" />
        <rect x="928" y="165" width="110" height="50" />

        {/* Row 5 */}
        <rect x="0" y="220" width="110" height="50" />
        <rect x="116" y="220" width="110" height="50" />
        <rect x="232" y="220" width="110" height="50" />
        <rect x="348" y="220" width="110" height="50" />
        <rect x="464" y="220" width="110" height="50" />
        <rect x="580" y="220" width="110" height="50" />
        <rect x="696" y="220" width="110" height="50" />
        <rect x="812" y="220" width="110" height="50" />
        <rect x="928" y="220" width="110" height="50" />
      </ContentLoader>
    </Fragment>
  );
}

const LoaderTitle = styled.p`
  margin: 20px auto 0;
  width: fit-content;
  color: ${palette('grayscale', 3)};
`;


