export const APPLICATION = 'APPLICATION';
export const APPLICATIONS = 'APPLICATIONS';
export const BUY_POINTS_GUIDELINE = 'BUY_POINTS_GUIDELINE';
export const BUY_POINTS_PATCH = 'BUY_POINTS_PATCH';
export const DISCUSS_QUOTE = 'DISCUSS_QUOTE';
export const CONDITIONS = 'CONDITIONS';
export const CONSTRUCTION_BUDGET = 'CONSTRUCTION_BUDGET';
export const CONSTRUCTION_BUDGET_IMPORT = 'CONSTRUCTION_BUDGET_IMPORT';
export const CONSTRUCTION_BUDGET_TEMPLATE = 'CONSTRUCTION_BUDGET_TEMPLATE';
export const CONTACTS = 'CONTACTS';
export const CONTACT_TYPES = 'CONTACT_TYPES';
export const DOCUMENTS = 'DOCUMENTS';
export const DOCUMENT_CATEGORIES = 'DOCUMENT_CATEGORIES';
export const DOWNLOAD_DOCUMENT_PDF = 'DOWNLOAD_DOCUMENT_PDF';
export const DRAW_REQUESTS = 'DRAW_REQUESTS';
export const ENTITIES = 'ENTITIES';
export const EXPORT_PROPERTIES = 'EXPORT_PROPERTIES';
export const FINAL_TERM_SHEET = 'FINAL_TERM_SHEET';
export const INITIAL_TERM_SHEET = 'INITIAL_TERM_SHEET';
export const PRELIMINARY_TERM_SHEET = 'PRELIMINARY_TERM_SHEET';
export const PROCESSOR_NOTES = 'PROCESSOR_NOTES';
export const PROFILE = 'PROFILE';
export const QUOTE = 'QUOTE';
export const QUOTE_PDF = 'QUOTE_PDF';
export const PRE_QUALIFICATION_LETTER_PDF = 'PRE_QUALIFICATION_LETTER_PDF';
export const REFS = 'REFS';
export const SCHEDULES = 'SCHEDULES';
export const SCHEDULES_CHECK_IMPORT_STATUS = 'SCHEDULES_CHECK_IMPORT_STATUS';
export const SCHEDULES_IMPORT = 'SCHEDULES_IMPORT';
export const SELECT_QUOTE = 'SELECT_QUOTE';
export const SETTINGS = 'SETTINGS';
export const TERM_SHEET = 'TERM_SHEET';
export const USER = 'USER';
