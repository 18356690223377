import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose, pure, withHandlers, withState } from 'recompose';
import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';

import Badge from 'components/shared/Badge';
import Button from 'components/shared/Button';
import Link from 'components/shared/Link';

import * as actions from 'actions';

import {
  applicantCurrentStatusPath,
  APPLICATION_PATH,
  APPLICATION_NEW_EXPENSES_PATH,
  APPLICATION_NEW_DRAW_REQUESTS_PATH,
  APPLICATION_PAYOFF_PATH,
  APPLICATION_EXTENSION_PATH,
} from 'constants/routes';
import { applicationPropTypesRequired } from 'propTypes';
import { decimalCurrencyFormat, dateFormat, getPath } from 'utils';

const propTypes = {
  ...applicationPropTypesRequired,
  termSheetLoading: PropTypes.bool.isRequired,
  preliminaryTermSheetLoading: PropTypes.bool.isRequired,
  downloadTermSheet: PropTypes.func.isRequired,
  downloadPreliminaryTermSheet: PropTypes.func.isRequired,
};

const defaultProps = {};

function ApplicationRow({
  application,
  downloadTermSheet,
  downloadPreliminaryTermSheet,
  preliminaryTermSheetLoading,
  termSheetLoading,
  undoWithDraw,
  withDraw,
}) {
  const {
    dataEntryStep,
    internalId,
    meta,
    properties,
    settlementDate,
    label,
    submittedToInitialUnderwritingAt,
    token,
    totalAmount,
    leadStep,
    monthlyPayment,
    maximumMonthlyPayment,
    displayShortType,
    udpatedAt,
    paymentStatus,
  } = application;

  const isPaidOff = paymentStatus === 'paid_off';

  return (
    <Row isGray={label === 'closed' && isPaidOff}>
      <Cell45>
        <Item>
          <Link
            to={applicantCurrentStatusPath(application.status, {
              token,
              leadStep,
              step: dataEntryStep,
            })}
            data-testid="appLink"
          >
            {internalId}
          </Link>

          {label === 'closed' && (
            <StyledBadge palette="alert" size="sm">
              Closed Loan
            </StyledBadge>
          )}
          {label === 'current' && (
            <StyledBadge palette="info" size="sm">
              Current Application
            </StyledBadge>
          )}

          {label === 'closed' && isPaidOff && (
            <StyledBadge palette="grayscale" size="sm" colorTone={2} tone={-2}>
              Paid Off
            </StyledBadge>
          )}
        </Item>

        <Item>
          <Strong>{displayShortType}: </Strong>
          {properties &&
            properties.map((property, index) => (
              <span key={`property-address-${application.id}-${property.id}`}>
                {meta.availableToEdit ? (
                  <Link to={getPath(APPLICATION_PATH, { token })}>{property.address}</Link>
                ) : (
                  property.address
                )}
                {index !== application.properties.length - 1 && ', '}
              </span>
            ))}
        </Item>
      </Cell45>
      <Cell>
        <Item isStrong>{decimalCurrencyFormat(totalAmount)}</Item>
        <Item isSmall>
          <List>
            <ListItem>Current Monthly Payment: {decimalCurrencyFormat(monthlyPayment)}</ListItem>
            <ListItem>Maximum Monthly Payment: {decimalCurrencyFormat(maximumMonthlyPayment)}</ListItem>
          </List>
        </Item>
      </Cell>
      <Cell isRight>
        <Item>Updated: {dateFormat(udpatedAt)}</Item>
        {submittedToInitialUnderwritingAt && (
          <Item>Submitted to UW: {dateFormat(submittedToInitialUnderwritingAt)}</Item>
        )}
        <Item>Settlement: {settlementDate ? dateFormat(settlementDate) : 'Not entered'}</Item>
      </Cell>

      <Cell isRight isActions>
        {meta.availableToEdit && (
          <StyledButton
            palette="grayscale"
            to={applicantCurrentStatusPath(application.status, {
              token,
              leadStep,
              step: dataEntryStep,
            })}
          >
            Edit
          </StyledButton>
        )}

        {meta.availableToPayAppraisal && (
          <StyledButton to={getPath(APPLICATION_NEW_EXPENSES_PATH, { token })}>Appraisal Payment</StyledButton>
        )}
        {meta.availableToRequestDraw && (
          <StyledButton to={getPath(APPLICATION_NEW_DRAW_REQUESTS_PATH, { token })}>Request Draw</StyledButton>
        )}
        {meta.availableToRequestPayoff && (
          <StyledButton to={getPath(APPLICATION_PAYOFF_PATH, { token })}>Request Payoff</StyledButton>
        )}
        {meta.availableToRequestExtension && (
          <StyledButton to={getPath(APPLICATION_EXTENSION_PATH, { token })}>Request Extension</StyledButton>
        )}

        {meta.availableToDownloadTermSheet && (
          <StyledButton onClick={downloadTermSheet} disabled={termSheetLoading}>
            {termSheetLoading ? 'Downloading...' : 'Term Sheet'}
          </StyledButton>
        )}

        {meta.availableToDownloadPreliminaryTermSheet && (
          <StyledButton onClick={downloadPreliminaryTermSheet}>
            {preliminaryTermSheetLoading ? 'Downloading...' : 'Quote'}
          </StyledButton>
        )}

        {meta.availableToWithdraw && (
          <StyledButton palette="danger" onClick={() => withDraw(token)}>
            Withdraw
          </StyledButton>
        )}
        {meta.availableToUndoWithdraw && <StyledButton onClick={() => undoWithDraw(token)}>Undo Withdraw</StyledButton>}
      </Cell>
    </Row>
  );
}

const buttonStyle = css`
  display: block;
  margin-bottom: 0.375rem;
  width: auto;
  text-transform: capitalize;
  opacity: 0.8;
  &:last-child {
    margin-bottom: 0;
  }
`;

const Row = styled.div`
  ${({ isGray }) => isGray && 'opacity: 0.6;'}
  display: flex;
  font-size: 0.875rem;
  align-items: center;
  width: 100%;
  border-bottom: 0.0625rem solid #d5dde9;
`;

const Cell = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 20%;
  align-items: ${ifProp('isRight', 'flex-end', 'flex-start')};
  padding: 10px 0;
`;

const Cell45 = styled(Cell)`
  flex: 1 0 40%;
`;

const Item = styled.div`
  margin-bottom: 0.375rem;
  &:last-child {
    margin-bottom: 0;
  }
  ${ifProp(
    'isStrong',
    css`
      font-size: 1rem;
      font-weight: bold;
    `,
  )};
  ${ifProp(
    'isSmall',
    css`
      font-size: 0.85em;
    `,
  )};
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const ListItem = styled.li`
  margin: 0;
`;

const Strong = styled.strong`
  text-transform: capitalize;
  font-weight: bold;
`;

const StyledBadge = styled(Badge)`
  margin-left: 0.625rem;
`;

const StyledButton = styled((props) => <Button bordered xs rounded palette="grayscale" {...props} />)`
  ${buttonStyle};
`;

ApplicationRow.propTypes = propTypes;
ApplicationRow.defaultProps = defaultProps;

function mapDispatchToProps(dispatch) {
  return {
    getPreliminaryTermSheetAction: bindActionCreators(actions.getPreliminaryTermSheet, dispatch),
    getTermSheetAction: bindActionCreators(actions.getTermSheet, dispatch),
    undoWithDraw: bindActionCreators(actions.undoWithDraw, dispatch),
    withDraw: bindActionCreators(actions.withDraw, dispatch),
  };
}

const enhance = compose(
  connect(
    null,
    mapDispatchToProps,
  ),
  withState('termSheetLoading', 'setTermSheetLoading', false),
  withState('preliminaryTermSheetLoading', 'setPreliminaryTermSheetLoading', false),
  withHandlers({
    downloadTermSheet: ({ setTermSheetLoading, getTermSheetAction, application }) => async () => {
      const { token } = application;
      setTermSheetLoading(true);
      await getTermSheetAction(token);
      setTermSheetLoading(false);
    },
    downloadPreliminaryTermSheet: ({
      getPreliminaryTermSheetAction,
      application,
      setPreliminaryTermSheetLoading,
    }) => async () => {
      const { token } = application;
      setPreliminaryTermSheetLoading(true);
      await getPreliminaryTermSheetAction(token);
      setPreliminaryTermSheetLoading(false);
    },
  }),
  pure,
);

export default enhance(ApplicationRow);
