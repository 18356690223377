import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose, pure, lifecycle } from 'recompose';
import ScrollToTopOnMount from 'hocs/ScrollToTopOnMount';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actions from 'actions';
import { requestInProcess } from 'selectors';
import { getApplicationDetail } from 'selectors/applications';

import { ConstructionBudgetForm, ConstructionBudgetShow } from 'components/blocks';
import { Spinner, Link } from 'components/shared';
import Wrapper from 'components/shared/Wrapper';
import { UserHeader} from 'components/blocks/User';
import { UserTemplate } from 'components/templates';

import { APPLICATION_CONSTRUCTION_BUDGET_PATH } from 'constants/routes';
import { WithUser } from 'hocs';
import { userDefaultProps, userPropTypes } from 'propTypes';
import { getPath } from 'utils';

import { ApplicationHeader } from '../shared';

const propTypes = {
  ...userPropTypes,
  properties: PropTypes.arrayOf(PropTypes.shape({})),
  initialValues: PropTypes.shape({}),
};

const defaultProps = {
  ...userDefaultProps,
  properties: [],
  initialValues: {},
};

function ConstructionBudgetPage(props) {
  const { application, loading, constructionBudget } = props;
  // TODO research why we request and don't use constructionBudget

  return (
    <UserTemplate header={<UserHeader />}>
      <ApplicationHeader />
      <StyledWrapper>
        <StyledConstructionBudgetPage id="constructionBudgetWrapper">
          {loading || !application ? (
            <StyledConstructionBudgetPageLoading>
              <Spinner />
            </StyledConstructionBudgetPageLoading>
          ) : (
            <React.Fragment>
              <StyledConstructionBudgetPageHeader>
                {application && application.properties && application.properties.length > 1 && (
                  <Link to={getPath(APPLICATION_CONSTRUCTION_BUDGET_PATH, { token: application.token })}>
                    To all Properties
                  </Link>
                )}
              </StyledConstructionBudgetPageHeader>
                <ConstructionBudgetForm availableToEditConstructionBudget={application && application.meta && application.meta.availableToEditConstructionBudget}/>
            </React.Fragment>
          )}
        </StyledConstructionBudgetPage>
      </StyledWrapper>
      <div id="modalPortal" />
    </UserTemplate>
  );
}

const StyledWrapper = styled(Wrapper)`
  width: 100%;
  max-width: 90rem;
`;

const StyledConstructionBudgetPage = styled.div`
  padding-bottom: 34px;
`;
const StyledConstructionBudgetPageLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const StyledConstructionBudgetPageHeader = styled.div`
  width: 100%;
  padding: 16px 15px;
`;

ConstructionBudgetPage.propTypes = propTypes;
ConstructionBudgetPage.defaultProps = defaultProps;

const mapStateToProps = (state) => {
  const application = getApplicationDetail(state);
  return {
    loading: requestInProcess(state, 'CONSTRUCTION_BUDGET'),
    constructionBudget: state.constructionBudget,
    application,
  };
};
function mapDispatchToProps(dispatch) {
  return {
    fetchConstructionBudget: bindActionCreators(actions.fetchConstructionBudget, dispatch),
    clearConstructionBudget: bindActionCreators(actions.clearConstructionBudget, dispatch),
  };
}

const enhance = compose(
  ScrollToTopOnMount,
  WithUser,
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  lifecycle({
    componentDidMount() {
      const { fetchConstructionBudget, match } = this.props;
      const { token, propertyId } = match.params;
      fetchConstructionBudget(token, propertyId);
    },
    componentWillUnmount() {
      const { clearConstructionBudget } = this.props;
      clearConstructionBudget();
    },
  }),

  pure,
);

export default enhance(ConstructionBudgetPage);
